import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as Yup from "yup";
import TextInput from "form-controls/TextInput";
import TextBox from "form-controls/TextBox";
import SelectBox from "form-controls/SelectBox";
import TimePicker from "form-controls/TimePicker";
import DateInput from "form-controls/DateInput";
import CheckBox from "form-controls/CheckBox";
import Button from "Button";
import Notice from "Notice";
import { optionList as directionsOptionList } from "directions";
import { Formik, Field, Form } from "formik";
import { fetch, withErrorHandler } from "graphql-helper";
import {
  createBooking,
  listSlots,
  deleteBooking,
  updateBooking,
  getSite,
  getLoadTypes
} from "./calendar-view-queries";
import { formatLocalDate } from "date-helper";
import Can from "Can";
import "./BookingForm.css";
import { formatWithDefaultTz } from "date-helper";
import { sortTrailerTypeOptions } from "../../../../utilities/trailer-utils";
import { sortHaulierOptions } from "../../../../utilities/haulier-utils";

const bookingValidationSchema = Yup.object().shape({
  time: Yup.string().required("Please choose a timeslot"),
  trailerTypeId: Yup.string().required("Please choose trailer type"),
  haulierId: Yup.string().required("Please enter haulier"),
  trailerOwnerId: Yup.string().required("Please enter trailer owner"),
  direction: Yup.string().required("Please choose direction"),
  loadType: Yup.string().required("Please choose load type")
});

const bookingTerms =
  "Your booking has been created. The journey reference must be quoted on arrival at the gatehouse or access will be denied. Please be aware failure to arrive on time for this booking increases the risk of delays.";

class BookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      timeslots: [],
      booking: [],
      showMessage: false,
      trailerTypes: [],
      hauliers: [],
      history: [],
      siteHauliers: [],
      exportData: [],
      appointment: null,
      loadTypes: []
    };
  }

  componentDidMount() {
    const { palletised, numberOfPallets } = this.initialValues;

    this.getTimeSlots(palletised, numberOfPallets);

    if (this.props.selectedBooking) {
      this.getHistory(this.props.selectedBooking.id);
    }

    getSite(parseInt(this.props.selectedSite)).then(data => {
      this.setState({});

      const trailerTypes = data.trailerTypes.map(type => {
        return { value: type.id, label: type.name };
      });

      const hauliers = this.filterHaulierData(data.hauliers);

      const siteHauliers = data.site.hauliers.map(haulier => {
        return { value: haulier.id, label: haulier.name };
      });

      this.setState({ siteHauliers, trailerTypes, hauliers });
      getLoadTypes(parseInt(this.props.selectedSite)).then(data => {
        this.setState({ loadTypes: data.loadTypes });
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.resetSlots();
    }

    if (prevState.deleting && this.state.deleting) {
      this.setState({ deleting: false });
    }

    if (
      this.props.selectedBooking &&
      this.props.selectedBooking !== prevProps.selectedBooking
    ) {
      this.getTimeSlots(
        this.props.selectedBooking.palletised,
        this.props.selectedBooking.numberOfPallets
      );

      this.getHistory(this.props.selectedBooking.id);
    } else if (this.props.selectedBooking !== prevProps.selectedBooking) {
      this.resetSlots();
      this.getHistory(this.props.selectedBooking.id);
    }
  }

  get isManualBooking() {
    return (
      this.props.selectedBooking &&
      this.props.selectedBooking.appointment === null
    );
  }

  filterHaulierData(haulierData) {
    // "Haulier" accounts will always have 1 haulier assigned to them
    // that could have been manually created but we still want to
    // display them in the list, in which case we don't want to filter
    // them out.
    if (haulierData.length > 1) {
      haulierData = haulierData.filter(haulier => {
        return !haulier.manuallyCreated;
      });
    }

    return haulierData.map(haulier => {
      return { value: haulier.id, label: haulier.name };
    });
  }

  get deletionReasons() {
    return [
      { value: "cancelled", label: "Order cancelled" },
      { value: "production", label: "Production change" },
      { value: "rebooked", label: "Load rebooked" }
    ];
  }

  get initialValues() {
    const date = this.props.selectedBooking
      ? this.props.selectedBooking.slotStart
      : this.props.selectedDate;

    const time = this.props.selectedBooking
      ? new Date(this.props.selectedBooking.slotStart).toUTCString()
      : "";

    return {
      date: formatWithDefaultTz(date, "YYYY-MM-DD"),
      time,
      journeyReference: "",
      origin: "",
      palletised: true,
      numberOfPallets: 0,
      haulierId:
        this.props.selectedBooking && this.props.selectedBooking.haulier
          ? this.props.selectedBooking.haulier.id
          : "",
      trailerOwnerId:
        this.props.selectedBooking && this.props.selectedBooking.trailerOwner
          ? this.props.selectedBooking.trailerOwner.id
          : "",
      trailerTypeId: this.props.selectedBooking
        ? this.props.selectedBooking.trailerType.id
        : "",
      requiresEmptyTrailer: false,
      notes: "",
      reasonDeleted: "",
      poNumber: "",
      direction: "",
      loadTypes: this.props.selectedBooking
        ? this.props.selectedBooking.loadType
        : "",
      checkInDate:
        this.props.selectedBooking &&
        this.props.selectedBooking.checkInCompleteAt
          ? formatWithDefaultTz(
              this.props.selectedBooking.checkInCompleteAt,
              "HH:mm"
            )
          : "",
      checkOutDate:
        this.props.selectedBooking && this.props.selectedBooking.departedAt
          ? formatWithDefaultTz(this.props.selectedBooking.departedAt, "HH:mm")
          : "",
      ...this.props.selectedBooking,
      driverName:
        (this.props.selectedBooking && this.props.selectedBooking.driverName) ||
        "",
      vehicleReg:
        (this.props.selectedBooking && this.props.selectedBooking.vehicleReg) ||
        "",
      journeyTag: this.state.appointment
        ? this.state.appointment.data["journey_tag"]
        : ""
    };
  }

  get canCreateRebooking() {
    return this.props.permissions.includes("warehouse.rebookings.create");
  }

  valuesForSubmission(values) {
    const isStaged = ["staged", "rebooking"].includes(values.direction);
    return {
      driverName: values.driverName || "",
      slotStart: new Date(values.time).toISOString(),
      haulierId: parseInt(values.haulierId),
      trailerOwnerId: parseInt(values.trailerOwnerId),
      origin: values.origin,
      trailerTypeId: parseInt(values.trailerTypeId),
      requiresEmptyTrailer: values.requiresEmptyTrailer,
      isRebooking: values.direction === "rebooking",
      notes: values.notes || "",
      loadType: values.loadType,
      reasonDeleted: values.reasonDeleted || "",
      palletised: values.palletised,
      numberOfPallets: parseInt(values.numberOfPallets, 10),
      siteId: parseInt(this.props.selectedSite),
      vehicleReg: values.vehicleReg || "",
      poNumber: values.poNumber,
      direction: isStaged ? "inbound" : values.direction,
      status: isStaged ? "staged" : undefined,
      date: values.date
    };
  }

  getHauliers() {
    if (this.state.siteHauliers.length > 0) {
      return this.state.siteHauliers;
    } else {
      return this.state.hauliers;
    }
  }

  handleSubmit(values, { setSubmitting }) {
    if (this.state.deleting) {
      this.props.handleErrors(
        deleteBooking(this.props.selectedBooking.id, values.reasonDeleted).then(
          () => {
            this.props.onDeleteBooking();
            setSubmitting(false);
          }
        ),
        "The booking has been deleted."
      );
    } else if (this.props.editing) {
      this.props.handleErrors(
        updateBooking(
          this.props.selectedBooking.id,
          this.valuesForSubmission(values)
        ).then(() => {
          setSubmitting(false);
          this.props.onUpdateBooking();
        }),
        "The booking has been updated"
      );
    } else {
      this.props
        .handleErrors(
          createBooking(this.valuesForSubmission(values)).then(data => {
            this.setState({ booking: data.booking, showMessage: true });
            setSubmitting(false);
            this.props.onCreateBooking(data.booking.journeyReference);
          }),
          "The booking has been created"
        )
        .then(() => setSubmitting(false));
    }
  }

  showNewBooking() {
    this.setState({ showMessage: false });
    const siteId =
      this.state.booking.direction == "inbound"
        ? this.state.booking.receivingSite.id
        : this.state.booking.sendingSite.id;

    this.props.history.push({
      pathname: `/bookings/site/${siteId}/booking/${this.state.booking.journeyReference}`
    });
  }

  getTimeSlots(isPalletised, numberOfPallets) {
    if (Number.isInteger(numberOfPallets) && isPalletised !== undefined) {
      listSlots(
        formatWithDefaultTz(this.props.selectedDate, "YYYY-MM-DD"),
        this.props.selectedSite,
        isPalletised,
        numberOfPallets,
        "external"
      ).then(data => {
        this.setState({
          timeslots: data.slots.availableSlots.map(ts => ({
            value: new Date(ts.slot_time).toUTCString(),
            label: formatWithDefaultTz(new Date(ts.slot_time), "HH:mm"),
            isAvailable: ts.is_available
          }))
        });
      });
    }
  }

  getHistory(id) {
    this.setState({ history: [], exportData: [] });
    fetch(`query {
      booking (id: ${id}) {
        appointment {
          data
        }
        exportData {
          photo
        }
        history {
          driverName
          vehicleReg
          updatedAt
        }
      }
    }`).then(({ booking }) => {
      this.setState({
        history: booking.history || [],
        exportData: booking.exportData || [],
        appointment: booking.appointment
      });
    });
  }

  get historicalDrivers() {
    return this.state.history.reduce((acc, h) => {
      if (!h.driverName) {
        return acc;
      }

      if (acc.length === 0) {
        return [h];
      }

      const lastDriver = acc[acc.length - 1];

      if (lastDriver.driverName !== h.driverName) {
        return [...acc, h];
      }

      if (lastDriver.updatedAt > h.updatedAt) {
        lastDriver.updatedAt = h.updatedAt;
      }

      return acc;
    }, []);
  }

  resetSlots() {
    this.setState({ timeslots: [] });
  }

  generateExpectedFieldString(appointmentField) {
    const { selectedBooking } = this.props;

    if (
      this.isManualBooking ||
      !selectedBooking ||
      selectedBooking.status === "new" ||
      !selectedBooking.appointment ||
      !selectedBooking.appointment.data ||
      !selectedBooking.appointment.data[appointmentField]
    )
      return "";

    return ` (expected: ${selectedBooking.appointment.data[appointmentField]})`;
  }

  render() {
    return (
      <div>
        {this.state.showMessage && (
          <Notice
            onDismiss={this.showNewBooking.bind(this)}
            message={bookingTerms}
          />
        )}
        <Formik
          validationSchema={bookingValidationSchema}
          enableReinitialize
          initialValues={this.initialValues}
          initialErrors
          onSubmit={this.handleSubmit.bind(this)}
        >
          {({ values, isSubmitting, errors, touched }) => (
            <Form>
              <Field
                name="date"
                label="Date"
                component={DateInput}
                placeholder="Select the date"
                error={touched.date && errors.date}
                disabled
              />
              <Field
                name="palletised"
                label="Palletised load"
                component={CheckBox}
                disabled={this.props.editing}
                onChange={e => {
                  this.getTimeSlots(
                    e.target.value !== "true",
                    parseInt(values.numberOfPallets, 10)
                  );
                }}
              />
              <Field
                name="numberOfPallets"
                label="Number of Pallets"
                component={TextInput}
                disabled={!values.palletised || this.props.editing}
                onChange={e =>
                  this.getTimeSlots(
                    values.palletised,
                    parseInt(e.target.value, 10)
                  )
                }
              />

              <Field
                name="time"
                label="Time"
                component={TimePicker}
                placeholder="Choose a timeslot"
                options={this.state.timeslots}
                disabled={this.props.editing}
                error={touched.time && errors.time}
              />
              <Field
                name="loadType"
                label="Load Type"
                placeholder="Select load type"
                options={this.state.loadTypes.map(lt => {
                  return { value: lt.name, label: lt.name };
                })}
                component={SelectBox}
                disabled={this.props.editing}
                error={touched.loadType && errors.loadType}
              />
              <Field
                name="direction"
                label="Direction"
                placeholder="Select direction"
                options={directionsOptionList(this.canCreateRebooking)}
                component={SelectBox}
                disabled={this.props.editing}
                error={touched.direction && errors.direction}
              />
              {this.props.editing && (
                <>
                  <Field
                    name="journeyReference"
                    label="Journey Reference"
                    component={TextInput}
                    disabled={true}
                  />
                  {this.state.appointment &&
                    this.state.appointment.data["journey_tag"] && (
                      <Field
                        name="journeyTag"
                        label="Journey Tag"
                        component={TextInput}
                        disabled={true}
                      />
                    )}
                </>
              )}
              {this.props.editing && this.initialValues.checkInDate && (
                <Field
                  name="checkInDate"
                  label="Check In Time"
                  component={TextInput}
                  disabled={true}
                />
              )}
              {this.props.editing && this.initialValues.checkOutDate && (
                <Field
                  name="checkOutDate"
                  label="Check Out Time"
                  component={TextInput}
                  disabled={true}
                />
              )}
              <Field
                name="poNumber"
                label="PO Number"
                component={TextInput}
                error={touched.poNumber && errors.poNumber}
              />
              <Field
                name="origin"
                label="Origin"
                component={TextInput}
                error={touched.origin && errors.origin}
              />
              <Field
                name="haulierId"
                label={`Haulier${this.generateExpectedFieldString("haulier")}`}
                placeholder="Select Haulier"
                options={sortHaulierOptions(this.getHauliers())}
                component={SelectBox}
                error={touched.haulierId && errors.haulierId}
              />
              <Field
                name="trailerOwnerId"
                label={`Trailer Owner${this.generateExpectedFieldString(
                  "trailer_owner"
                )}`}
                placeholder="Select Trailer Owner"
                options={sortHaulierOptions(this.getHauliers())}
                component={SelectBox}
                error={touched.trailerOwnerId && errors.trailerOwnerId}
              />
              <Field
                name="trailerTypeId"
                label={`Trailer Type${this.generateExpectedFieldString(
                  "trailer_type"
                )}`}
                placeholder="Select Trailer Type"
                options={sortTrailerTypeOptions(this.state.trailerTypes)}
                component={SelectBox}
                error={touched.trailerTypeId && errors.trailerTypeId}
              />
              <Field
                name="requiresEmptyTrailer"
                label="Requires empty trailer/empty leg in"
                component={CheckBox}
                disabled={this.props.editing}
              />

              {this.props.editing && (
                <>
                  <Field
                    name="driverName"
                    label="Driver Name"
                    component={TextInput}
                    error={touched.driverName && errors.driverName}
                  />
                  <Field
                    name="vehicleReg"
                    label="Vehicle Registration"
                    component={TextInput}
                    error={touched.vehicleReg && errors.vehicleReg}
                  />
                </>
              )}

              <Field
                name="notes"
                label="Notes"
                placeholder="Any additional notes should be added here."
                component={TextBox}
              />

              {this.historicalDrivers.length > 0 && (
                <div style={{ marginBottom: "1rem" }}>
                  <h4
                    style={{
                      font: '700 15px/24px "Source Sans Pro", sans-serif',
                      marginBottom: "1rem"
                    }}
                  >
                    Previously checked in
                  </h4>
                  {this.historicalDrivers.map((d, idx) => (
                    <p
                      key={idx}
                      style={{
                        font: '400 15px/24px "Source Sans Pro", sans-serif'
                      }}
                    >
                      {d.driverName} - {d.vehicleReg} -{" "}
                      {formatLocalDate(d.updatedAt + "Z")}
                    </p>
                  ))}
                </div>
              )}

              {this.state.exportData.length > 0 && (
                <div style={{ marginBottom: "1rem" }}>
                  <h4
                    style={{
                      font: '700 15px/24px "Source Sans Pro", sans-serif',
                      marginBottom: "1rem"
                    }}
                  >
                    Export photos
                  </h4>
                  {this.state.exportData.map(d => (
                    <img
                      src={d.photo}
                      alt="Export load photo"
                      style={{ width: "512px" }}
                    />
                  ))}
                </div>
              )}

              {this.props.editing && (
                <div>
                  {this.state.deleting && (
                    <div>
                      <div className="cancel-booking">
                        <Field
                          name="reasonDeleted"
                          label="Reason for deletion"
                          placeholder="Select Reason"
                          options={this.deletionReasons}
                          component={SelectBox}
                        />
                      </div>
                      <div className="button-row">
                        <Button
                          variant="secondary"
                          size="small"
                          title="Close"
                          onClick={() => {
                            this.setState({ deleting: false });
                          }}
                          disabled={isSubmitting}
                        />
                        <Button
                          type="submit"
                          variant="primary"
                          size="small"
                          title="Confirm Delete"
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  )}
                  {!this.state.deleting && (
                    <div>
                      <div className="button-row">
                        <Can
                          when={
                            this.isManualBooking
                              ? "bookings.calendar.delete"
                              : "bookings.calendar.deleteAtlas"
                          }
                        >
                          <Button
                            variant="secondary"
                            size="small"
                            title="Delete"
                            onClick={() => {
                              this.setState({ deleting: true });
                            }}
                            disabled={isSubmitting}
                          />
                        </Can>
                        <Can when="bookings.calendar.update">
                          <Button
                            type="submit"
                            variant="primary"
                            size="small"
                            title="Save"
                            disabled={isSubmitting}
                          />
                        </Can>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {!this.props.editing && (
                <Can when="bookings.calendar.create">
                  <div className="button-col">
                    <Button
                      type="submit"
                      variant="primary"
                      size="small"
                      title="Add booking"
                      disabled={isSubmitting}
                    />
                  </div>
                </Can>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { permissions } }) => ({
  permissions
});

export default withErrorHandler(
  withRouter(connect(mapStateToProps)(BookingForm))
);
