import React, { useState } from "react";
import AppTemplate from "templates/AppTemplate";
import Content from "Content";
import { formatLocalDate } from "date-helper";
import Papa from "papaparse";
import Button from "Button";
import { importTransporeonBookings } from "./bookings-from-csv-page/csv-queries";
// import { addMessage } from "redux/app";

export default function BookingsFromCsvPage(params) {
  console.log(params);
  const [bookings, setBookings] = useState({});
  const [uploadResult, setUploadResult] = useState([]);
  const [fileInputRef, setFileInputRef] = useState();

  const changeHandler = event => {
    setBookings({});
    setUploadResult([]);
    // console.log(event.target.files[0]);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        // console.log(results);
        setBookings(
          results.data.map(d => {
            d;
            return {
              slotStart: new Date(d["Booking time"]).toISOString(),
              driverName: `${d["Driver first name"]} ${d["Driver last name"]}`,
              vehicleReg: d["Licence plate No."],
              numberOfPallets: d["Pallets"] ? parseInt(d["Pallets"]) : 0,
              // palletised: d["Pallets"] ? true : false,
              notes: d["Comment"],
              // direction:
              //   d["Loading type"] == "Delivery" ? "inbound" : "outbound", // ?
              direction: "inbound",
              // siteId: "",
              toSiteShortCode: "HH2",
              fromSiteShortCode: "IMPT",
              journeyReference: d["Transport number"],
              // trailerTypeId: "",
              trailerType: d["Gate"] === "CURTAIN" ? "TAUT" : "BOX",
              loadType: "Finished Goods",
              // haulierId: "",
              haulierShortCode: "IMPT",
              // trailerOwnerId: "",
              trailerOwnerShortCode: "IMPT",
              loadNumber: d["Transport number"],
              receiptNumber: d["Delivery number"],
              heatSensitive: d["Temperature"] ? true : false,
              sealNumber: d["Seal No."]

              // origin: "",
              // requiresEmptyTrailer: false,
              // reasonDeleted: "",
              // poNumber: "",
              // checkInDate: "",
              // checkOutDate: "",
              // journeyTag: "",
              // isRebooking: ` values.direction === "rebooking"`,
              // status: `isStaged ? "staged" : undefined`,
              // appointment: {
              //   vet_check_required: d["Additional information"].includes(
              //     "Vet Cert. Req"
              //   )
              //     ? "Y"
              //     : "N"
              // }
            };
          })
        );
      }
    });
  };

  return (
    <AppTemplate>
      <Content>
        <div className="dash-row">
          <div className="content-block" style={{ flex: 1 }}>
            <h3>Import bookings from CSV</h3>
            <h4>CSV needs to have Transporeon booking export formatting</h4>
            {/* File Uploader */}
            <input
              type="file"
              name="file"
              accept=".csv"
              ref={ref => setFileInputRef(ref)}
              onChange={changeHandler}
            />
            {bookings.length > 0 && (
              <div>
                <h4>
                  Preview (this is how Mercurius will interpret your CSV):
                </h4>
                <table>
                  <thead>
                    <tr>
                      <th>Slot start</th>
                      <th>Journey Ref</th>
                      <th>From Site</th>
                      <th>To Site</th>
                      <th>Trailer Type</th>
                      <th>Load Type</th>
                      <th>Load Number</th>
                      <th>Haulier</th>
                      <th>Trailer Owner</th>
                      <th>Driver name</th>
                      <th>Vehicle Reg</th>
                      {/* <th>Palletised?</th> */}
                      <th>Pallets</th>
                      <th>Direction</th>
                      <th>Receipt number</th>
                      <th>Seal No.</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.map((b, i) => (
                      <tr key={i}>
                        <td>{formatLocalDate(b.slotStart)}</td>
                        <td>{b.journeyReference}</td>
                        <td>{b.fromSiteShortCode}</td>
                        <td>{b.toSiteShortCode}</td>
                        <td>{b.trailerType}</td>
                        <td>{b.loadType}</td>
                        <td>{b.loadNumber}</td>
                        <td>{b.haulierShortCode}</td>
                        <td>{b.trailerOwnerShortCode}</td>
                        <td>{b.driverName}</td>
                        <td>{b.vehicleReg}</td>
                        {/* <td>{b.palletised ? "Yes" : "No"}</td> */}
                        <td>{b.numberOfPallets}</td>
                        <td>{b.direction}</td>
                        <td>{b.receiptNumber}</td>
                        <td>{b.sealNumber}</td>
                        <td>{b.notes}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  variant="secondary"
                  size="small"
                  title="Upload"
                  onClick={() => {
                    importTransporeonBookings(bookings)
                      .then(result => {
                        setUploadResult(result.importTransporeonBookings);
                        setBookings({});
                        fileInputRef.value = "";
                      })
                      .catch(errors => {
                        console.log("CSV upload error", errors);
                      });
                  }}
                />
              </div>
            )}
            <div style={{ paddingTop: 20 }}>
              {uploadResult.map(result => {
                if (!result.errors) {
                  return (
                    <div
                      style={{ color: "green" }}
                      key={result.journey_reference}
                    >
                      {result.journey_reference} - success
                    </div>
                  );
                }
                const errors =
                  typeof result.errors === "string"
                    ? [result.errors]
                    : result.errors;
                return (
                  <div
                    style={{ color: "red", paddingTop: 10 }}
                    key={result.journey_reference}
                  >
                    {result.journey_reference}
                    <div>
                      {errors.map((error, i) => (
                        <div key={i}> - {error}</div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Content>
    </AppTemplate>
  );
}
