import { fetch } from "graphql-helper";
export function importTransporeonBookings(bookings) {
  console.log(bookings);
  return fetch(
    `
    mutation ImportTransporeonBookings($transporeonBooking: [TransporeonBooking]) {
      importTransporeonBookings(transporeon_bookings: $transporeonBooking) {
        journey_reference
        errors
      }
    }`,
    { transporeonBooking: bookings }
  );
}
