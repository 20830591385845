import React, { useEffect, useState } from "react";

import Button from "Button";
import Can from "Can";
import Loader from "Loader";
import Barcode from "../device-management-page/Barcode";
import DeviceTable from "../device-management-page/DeviceTable";
import ApplicationTable from "../device-management-page/ApplicationTable";
import config from "../../../../config";

import { generateDeviceRegistrationLink } from "api/devices";
import { getSite } from "./site-admin-queries";

export default ({ selectedSite }) => {
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [newDeviceToken, setNewDeviceToken] = useState(null);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const applications = [
      {
        name: "Shunter",
        downloadLink:
          "https://mercurius-app-builds.s3.eu-west-2.amazonaws.com/shunter-app-1-4-2.apk",
        previousBuild:
          "https://mercurius-app-builds.s3.eu-west-2.amazonaws.com/shunter-app-1-3-0.apk"
      }
    ];

    if (config.REACT_APP_CLIENT === "york" || !config.REACT_APP_CLIENT) {
      applications.push({
        name: "Gatehouse Beta",
        downloadLink:
          "https://mercurius-app-builds.s3.eu-west-2.amazonaws.com/nestle-gatehouse-poc-04-03-2024.apk"
      });
    } else {
      applications.push({
        name: "Gatehouse",
        downloadLink:
          "https://mercurius-app-builds.s3.eu-west-2.amazonaws.com/gatehouse-app-2-0-2.apk"
      });
    }

    if (config.REACT_APP_CLIENT !== "york") {
      applications.push({
        name: "Relay tester",
        message:
          "This app is provided for debugging purposes. Please do not download it unless you are testing or providing feedback.",
        downloadLink:
          "https://mercurius-app-builds.s3.eu-west-2.amazonaws.com/relay-test-5.apk"
      });
    }
    setApplications(applications);
  }, []);

  useEffect(() => {
    getSite(selectedSite.id).then(data => {
      const mappedDevices = data.site.devices.map(x => ({
        ...x,
        type: "tablet",
        lastAccessTime: "N/A"
      }));
      setDevices(mappedDevices);
    });
  }, [selectedSite]);

  const handleExpiry = () => {
    setTokenExpired(true);
    setNewDeviceToken(null);
  };

  const generateBarcode = () => {
    setLoading(true);
    generateDeviceRegistrationLink(selectedSite.id).then(deviceToken => {
      setNewDeviceToken(JSON.stringify(deviceToken));
      setLoading(false);
      setTokenExpired(false);
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <h3>Devices</h3>
      {!!devices.length && <DeviceTable devices={devices} />}
      {!devices.length && (
        <p>
          There are no devices currently registered. Use the button below to
          generate an access token for the device.
        </p>
      )}
      <Can when="settings.devices.register">
        <h3>Register a new device</h3>
        {newDeviceToken && (
          <>
            <p>Scan the barcode below to register the device.</p>
            <Barcode
              text={newDeviceToken}
              expiresAfter={120}
              onExpiry={() => handleExpiry()}
            />
            <Button
              variant="primary"
              onClick={() => generateBarcode()}
              title="Get new code"
            />
          </>
        )}
        {!newDeviceToken && (
          <>
            {tokenExpired && (
              <p>The token has expired. Please regenerate below.</p>
            )}
            <Button
              variant="primary"
              onClick={() => generateBarcode()}
              title="Generate barcode"
            />
          </>
        )}
      </Can>
      <Can when="settings.devices.register">
        <h3>Download links</h3>
        <p>
          Scan the barcode below using your phone's camera to download the apps.
        </p>
        <ApplicationTable applications={applications} />
      </Can>
    </>
  );
};
